<template>
  <div class="page">
    <b-card>
      <b-button
        variant="outline-primary"
        class="mb-2"
        @click="$router.push({name: 'Create Ticket'})"
      >
        <feather-icon icon="PlusIcon" />
        Add New Ticket
      </b-button>
      <b-table
        responsive="sm"
        :items="tickets"
      >
        <template #cell(ticketID)="data">
          <router-link :to="'/support/' + data.item.id">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(priority)="data">
          <b-badge
            pill
            class="text-capitalize"
            :variant="getVariant(data.value)"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="getVariant(data.value)"
          >
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BButton, BBadge,
  },
  data() {
    return {
      fields: ['ticketID', 'title', 'category', 'priority', 'status', 'updated', 'created'],
      tickets: [],
    }
  },
  created() {
    this.$store.dispatch('supports/getTickets').then(response => {
      if (response.code === 200) {
        this.tickets = response.data.map(data => {
          return {
            id: data.id,
            ticketID: data.support_ticket_id,
            title: data.title,
            category: data.support_category.subcategory_name,
            priority: data.priority,
            status: data.status,
            updated: new Date(data.updated_at).toLocaleString('en-CA'),
            created: new Date(data.created_at).toLocaleString('en-CA'),
          }
        })
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    getVariant(status) {
      console.log(status)
      switch (status) {
        case 'Active': return 'success'
        case 'Open': return 'success'
        case 'moderate': return 'warning'
        case 'Closed': return 'danger'
        case 'New Comment': return 'primary'
        case 'Solved': return 'success'
        default: return 'dark'
      }
    },
  },
}
</script>

<style>

</style>
